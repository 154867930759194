import React from "react";

import * as classes from "./Assets.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function AssetText2(props) {
    // {`${props.description.replace(/^(.{1}[^\s]*).*/, '$1')}
    //     ...`}
    const truncate = (str, max, suffix) => ([...str].length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(" "))}${suffix}`);

    return (
        <div className={classes.Asset}>
            <div className={classes.AssetTitle} onClick={props.onClick}>
                {/*<div className={classes.icon}>
          <FontAwesomeIcon icon='info-circle' size='1x' />
        </div>*/}
                {props.title && truncate(props.title, 50, "...")}
            </div>

            <div className={classes.AssetInfo}>
                <div className={classes.icon}>
                    <FontAwesomeIcon icon="clock" size="1x" />
                </div>

                {props.info}
            </div>

            <div className={classes.AssetDuration}>
                {" "}
                <div className={classes.icon}>
                    <FontAwesomeIcon icon="hourglass-start" size="1x" />
                </div>
                {props.duration}
            </div>
        </div>
    );
}
