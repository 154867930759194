import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import Loader from "react-loader-spinner";
import { useHistory } from "react-router-dom";
// import { useMyContext } from "../../contexts/StateHolder";
// import AssetsItem from "./AssetsItem";
import AssetText2 from "./AssetText2";
import { convertDuration } from "../../scripts/utils";
import { formatAssetDuration0 } from "../../scripts/assetUtils";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faEye, faHourglassHalf } from "@fortawesome/free-solid-svg-icons";

//import makeData from "./makeData";

import * as classes from "./LatestAndMostPopularAssets.module.css";

// import { getLatestAssets } from "../../../scripts/dataHandlers";

import { getKpiValuesFromOpenSearch } from "../../scripts/openSearchHandler";

export default function LatestAndMostPopularAssets(props) {
    const history = useHistory();

    const [showLoader1, setShowLoader1] = useState(true);
    const [showLoader2, setShowLoader2] = useState(true);

    const [latestAssetsTotalImpressionsVOD, setLatestAssetsTotalImpressionsVOD] = useState([]);
    const [latestAssetsTotalPlaysVOD, setLatestAssetsTotalPlaysVOD] = useState([]);
    const [latestAssetsTotalUniqueViewersVOD, setLatestAssetsTotalUniqueViewersVOD] = useState([]);
    const [latestAssetsTotalWatchedMinutesVOD, setLatestAssetsTotalWatchedMinutesVOD] = useState([]);

    const [popularAssetsTotalImpressionsVOD, setPopularAssetsTotalImpressionsVOD] = useState([]);
    const [popularAssetsTotalPlaysVOD, setPopularAssetsTotalPlaysVOD] = useState([]);
    const [popularAssetsTotalUniqueViewersVOD, setPopularAssetsTotalUniqueViewersVOD] = useState([]);
    const [popularAssetsTotalWatchedMinutesVOD, setPopularAssetsTotalWatchedMinutesVOD] = useState([]);

    // TODO: give maximum number of assets to show a.k.a. limit as a prop

    const organizationId = props.organizationId;
    const secret = props.secret;
    // const userId = props.userId;
    const latestAssets = props.latestAssets;
    const popularAssets = props.popularAssets;

    // console.log("latestAssets", latestAssets);
    // console.log("popularAssets", popularAssets);

    const clickhandler = (el) => {
        // setCurrentAsset(el);

        // console.log("setCurrentAsset", el);

        history.push(`/analytics/vod/asset/${el.id}/overview`);
    };

    // console.log(props);

    // Adapted from https://react-table.tanstack.com/docs/examples/basic

    const makeHeader = (headerName) => {
        return [
            {
                Header: headerName,
                columns: [
                    {
                        Header: "",
                        accessor: "index",
                    },
                    {
                        Header: "",
                        accessor: "thumbnail",
                    },
                    {
                        Header: "Video Details",
                        accessor: "name",
                    },
                    {
                        Header: "Unique Viewers",
                        accessor: "uniqueViewers",
                    },
                    {
                        Header: "Impressions",
                        accessor: "impressions",
                    },
                    {
                        Header: "Plays",
                        accessor: "plays",
                    },
                    {
                        Header: "Avg View Time",
                        accessor: "averageViewingTime",
                    },

                    // {
                    //     Header: "Avg. Percentage Watched",
                    //     accessor: "avgPercentageWatched",
                    // },
                ],
            },
        ];
    };

    const getUniqueViewers = (assetId, assetsTotalUniqueViewersVOD) => {
        if (!assetsTotalUniqueViewersVOD) {
            return 0;
        }

        let s = "" + assetId;
        let v = assetsTotalUniqueViewersVOD.find((a) => {
            return a.assetId === s;
        });

        if (v) {
            return v.value;
        }

        return 0;
    };

    const getImpressions = (assetId, assetsTotalImpressionsVOD) => {
        if (!assetsTotalImpressionsVOD) {
            return 0;
        }

        let s = "" + assetId;
        let v = assetsTotalImpressionsVOD.find((a) => {
            return a.assetId === s;
        });

        if (v) {
            return v.value;
        }

        return 0;
    };

    const getPlays = (assetId, assetsTotalPlaysVOD) => {
        if (!assetsTotalPlaysVOD) {
            return 0;
        }

        let s = "" + assetId;
        let v = assetsTotalPlaysVOD.find((a) => {
            return a.assetId === s;
        });

        if (v) {
            return v.value;
        }

        return 0;
    };

    const getAverageViewingTime = (assetId, assetsTotalPlaysVOD, assetsTotalWatchedMinutesVOD) => {
        if (!assetsTotalPlaysVOD) {
            return 0;
        }
        if (!assetsTotalWatchedMinutesVOD) {
            return 0;
        }

        let s = "" + assetId;
        let pv = assetsTotalPlaysVOD.find((a) => {
            return a.assetId === s;
        });
        let mv = assetsTotalWatchedMinutesVOD.find((a) => {
            return a.assetId === s;
        });

        if (mv && pv) {
            let p = Number(pv.value);
            let m = Number(mv.value);
            let tawt = 0;
            if (p > 0) {
                tawt = Number(m / p);
            } else if (Number(m) === 0.0 || Number(p) === 0.0) {
                // if plays or watched minutes is 0, just set the average to 0
                tawt = Number(0.0);
            }
            return formatAssetDuration0(tawt);
        }

        return 0;
    };

    const getAveragePercentageWatched = (assetId, duration, assetsTotalPlaysVOD, assetsTotalWatchedMinutesVOD) => {
        if (!assetsTotalPlaysVOD) {
            return 0;
        }
        if (!assetsTotalWatchedMinutesVOD) {
            return 0;
        }

        let s = "" + assetId;
        let pv = assetsTotalPlaysVOD.find((a) => {
            return a.assetId === s;
        });
        let mv = assetsTotalWatchedMinutesVOD.find((a) => {
            return a.assetId === s;
        });

        // console.log("getAveragePercentageWatched, mv, pv, duration", mv, pv, duration);

        if (mv && pv) {
            let p = Number(pv.value);
            let m = Number(mv.value);
            let tawt = 0;

            // console.log("getAveragePercentageWatched, m, p", m, p);

            // average
            if (p > 0) {
                tawt = m / p;
            } else if (m === 0 || p === 0) {
                // if plays or watched minutes is 0, just set the average to 0
                tawt = 0;
            }

            let avg = 0;

            if (duration > 0) {
                let durationMinutes = duration / 60.0;

                avg = (100.0 * tawt) / durationMinutes;
            }

            // round to 1 decimal
            // let percent = Math.round(10.0 * avg) / 10.0;

            // console.log("--> avg", avg);

            let percent = Math.round(avg);

            // console.log("--> returning", percent);

            return percent;
        }

        return 0;
    };

    // const assetClickHandler = (el) => {
    //     setCurrentAsset(el);

    //     // console.log("setCurrentAsset", el);

    //     history.push(`/analytics/vod/asset/${el.id}/overview`);
    // };

    const columns1 = useMemo(() => makeHeader(" "), []);
    const columns2 = useMemo(() => makeHeader(" "), []);

    const memoFunction1 = () => {
        if (latestAssets !== null) {
            setShowLoader1(false);
        }

        return makeItems(
            latestAssets,
            latestAssetsTotalImpressionsVOD,
            latestAssetsTotalPlaysVOD,
            latestAssetsTotalUniqueViewersVOD,
            latestAssetsTotalWatchedMinutesVOD
        );
    };

    const memoFunction2 = () => {
        if (popularAssets !== null) {
            setShowLoader2(false);
        }

        return makeItems(
            popularAssets,
            popularAssetsTotalImpressionsVOD,
            popularAssetsTotalPlaysVOD,
            popularAssetsTotalUniqueViewersVOD,
            popularAssetsTotalWatchedMinutesVOD
        );
    };

    const makeItems = (assets, assetsTotalImpressionsVOD, assetsTotalPlaysVOD, assetsTotalUniqueViewersVOD, assetsTotalWatchedMinutesVOD) => {
        // console.log("assets", assets);
        // console.log("assets  length", assets.length);

        if (assets === null) {
            // No assets yet, so just return an empty array, which means the table is empty.
            return [];
        }

        const result = assets.map((asset, index) => {
            // console.log("thumb", asset.thumbnailSmall);
            let item = {
                index: index + 1,
                thumbnail: <img className={classes.assetImage} src={asset.thumbnailSmall + "&width=100"} alt="thumbnail" onClick={() => clickhandler(asset)} />,
                name: (
                    // <div>
                    //     <div>{asset.name}</div>
                    //     <div>user and date here</div>
                    // </div>
                    <AssetText2
                        title={
                            asset.name && asset.name.replace(/<[^>]*>?/gm, "")
                            // el.publicName[el.defaultLanguage]
                            //     ? el.publicName[el.defaultLanguage]
                            //     : el.name[el.defaultLanguage] && el.name.en_US.replace(/<[^>]*>?/gm, "")
                        }
                        onClick={() => clickhandler(asset)}
                        info={
                            asset.date ? moment(asset.date).format("MMMM Do YYYY, HH:mm ") : ""
                            // el.startTimePublic
                            //     ? moment(el.startTimePublic).format("MMMM Do YYYY, HH:mm ")
                            //     : moment(el.startTime).format("MMMM Do YYYY, HH:mm ")
                        }
                        duration={asset.durationPublic ? convertDuration(asset.durationPublic) : convertDuration(asset.duration)}
                    />
                ),
                uniqueViewers: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon="user" size="1x" />
                        </div>
                        {getUniqueViewers(asset.id, assetsTotalUniqueViewersVOD)}
                    </div>
                ),
                impressions: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon={faEye} size="1x" />
                        </div>
                        {getImpressions(asset.id, assetsTotalImpressionsVOD)}
                    </div>
                ),
                plays: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon="play" size="1x" />
                        </div>
                        {getPlays(asset.id, assetsTotalPlaysVOD)}
                    </div>
                ),
                averageViewingTime: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon={faHourglassHalf} size="1x" />
                        </div>
                        {getAverageViewingTime(asset.id, assetsTotalPlaysVOD, assetsTotalWatchedMinutesVOD)}
                    </div>
                ),

                avgPercentageWatched: (
                    <div className={classes.AssetInfo}>
                        <div className={classes.icon}>
                            <FontAwesomeIcon icon={faCheckCircle} size="1x" />
                        </div>
                        {getAveragePercentageWatched(asset.id, asset.duration, assetsTotalPlaysVOD, assetsTotalWatchedMinutesVOD) + " %"}
                    </div>
                ),
            };
            return item;
        });

        return result;
    };

    // //const data = useMemo(() => makeData(3), [latestAssets]);
    const latestAssetsData = useMemo(
        memoFunction1,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [latestAssets, latestAssetsTotalImpressionsVOD, latestAssetsTotalPlaysVOD, latestAssetsTotalUniqueViewersVOD, latestAssetsTotalWatchedMinutesVOD]
    );

    // //const data = useMemo(() => makeData(3), [popularAssets]);
    const popularAssetsData = useMemo(
        memoFunction2,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [popularAssets, popularAssetsTotalImpressionsVOD, popularAssetsTotalPlaysVOD, popularAssetsTotalUniqueViewersVOD, popularAssetsTotalWatchedMinutesVOD]
    );

    const getKpis = async (name, assets) => {
        // console.log("-----> getKpiThisPeriod: name, organizationId, createdBy", name, organizationId, createdBy);

        if (!assets) return [];

        let assetIds = assets.map((asset) => asset.id);

        // console.log("getKpis: latestAsset ids", assetIds);

        const groupItemId = null;
        let returnArray = await getKpiValuesFromOpenSearch(name, organizationId, groupItemId, assetIds); //, createdBy, assetId, eventId, startDateThisPeriod, endDateThisPeriod);

        console.log("getKpis: returnArray", returnArray);

        return returnArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            const i = await getKpis("totalImpressionsVOD", latestAssets);
            setLatestAssetsTotalImpressionsVOD(i);
            const p = await getKpis("totalPlaysVOD", latestAssets);
            setLatestAssetsTotalPlaysVOD(p);
            const v = await getKpis("totalUniqueViewersVOD", latestAssets);
            setLatestAssetsTotalUniqueViewersVOD(v);
            const m = await getKpis("totalWatchedMinutesVOD", latestAssets);
            setLatestAssetsTotalWatchedMinutesVOD(m);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, secret, latestAssets]); //, createdBy, startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    useEffect(() => {
        const fetchData = async () => {
            const i = await getKpis("totalImpressionsVOD", popularAssets);
            setPopularAssetsTotalImpressionsVOD(i);
            const p = await getKpis("totalPlaysVOD", popularAssets);
            setPopularAssetsTotalPlaysVOD(p);
            const v = await getKpis("totalUniqueViewersVOD", popularAssets);
            setPopularAssetsTotalUniqueViewersVOD(v);
            const m = await getKpis("totalWatchedMinutesVOD", popularAssets);
            setPopularAssetsTotalWatchedMinutesVOD(m);
        };
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId, secret, popularAssets]); //, createdBy, startISODateThisPeriod, endISODateThisPeriod, startISODateLastPeriod, endISODateLastPeriod]);

    const Table = ({ columns, data, header }) => {
        // Use the state and functions returned from useTable to build your UI
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data,
        });

        // console.log("data", data);

        // Render the UI for your table
        return (
            <div>
                <h2>{header}</h2>
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>{column.render("Header")}</th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr className={classes.resultItemTitle} {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <>
            {/* <div className={classes.Assets_dashboard_title}>
                {props.showLoader && (
                    <div className={classes.Assets_loader}>
                        {" "}
                        <Loader type="Oval" color="#394855" height={300} width={300} />
                    </div>
                )}
            </div>{" "}
            <div className={classes.Assets_dashboard_title}>
                {" "}
                {latestAssets.length > 0 && (
                    <AssetsItem
                        assets={latestAssets}
                        // .filter((event) => event.startTime < startDay && event.startTime > monthAgo)
                        // .sort((a, b) => b.startTime - a.startTime)}
                        heading={"Latest and most popular video assets"}
                        organizationId={props.organizationId}
                        showNoImage={true}
                    />
                )}{" "}
            </div>{" "} */}
            <div className={classes.row}>
                <div className={classes.column}>
                    <Table columns={columns1} data={latestAssetsData} header={"Latest Assets"} />
                    {showLoader1 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div>
                <div className={classes.column}>
                    <Table columns={columns2} data={popularAssetsData} header={"Most Popular"} />
                    {showLoader2 && (
                        <div className={classes.Assets_loader}>
                            {" "}
                            <Loader type="Oval" color="#394855" height={300} width={300} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
